* {
  /* Reset the sizing */
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eaf6f1;
}

.heui-error {
  color: #b50000;
}

:root {
  /* Theming for Amplify UI */
  --amplify-primary-color: #005A8A;
  --amplify-primary-tint:  #005A8A;
  --amplify-primary-shade: #005A8A;

  --amplify-font-family: "Roboto", sans-serif;
}
amplify-authenticator {
  /* Allow Authenticator to fit in page */
  --container-height: unset;
  --padding: 16px;
}
