.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#welcome {
  color: white;
  font-size: 50px;
}

#container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.signinButtons {
  display: flex;
  flex-direction: column;
}

.item {
  max-width: 50%;
}

.loginButton {
  width: 300px;
  margin-bottom: 20px !important;
}


#signinButton {
  background-color: 005a8a !important;
  color: white !important;
}

#navContainer {
  display: flex;
  align-items: center;
  justify-content:  space-around;
  flex-wrap: wrap;
  font-weight: bold;
}

#topNavContainer {
  display: flex;
  align-items: center;
  justify-content:  right;
  flex-wrap: wrap;
  font-weight: bold;
  flex-flow: row-reverse
}

.KMS {
  margin-right: 30px;
}

#test {
  font-size: 50px;
}

.green {
  background-color: '#6ead54 '
}

.yellow {
  background-Color: '#ede73e '
}

.red {
  background-color: '#a33038 '
}

.noBorderRight:last-child {
  border-right: 0px solid white !important;
}
